<template>
  <v-container fluid>
    <v-card>
      <v-row no-gutters class="px-5 mb-5 pt-2 pb-3">
        <v-col cols="12" lg="2" sm="12" class="mr-5">
          <v-select
            v-model="search"
            :items="search_items"
            label="검색항목"
            item-text="text"
            item-value="value"
            hide-details
          ></v-select>
        </v-col>
        <v-col cols="12" lg="2" sm="12">
          <v-text-field
            v-model="keyword"
            @keyup.enter="getData()"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          lg="1"
          sm="12"
          class="mt-2"
          style="white-space: nowrap"
        >
          <v-btn color="primary" @click="getData()" class="mr-2">검색</v-btn>
          <v-btn @click="refresh()">초기화</v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          :page.sync="page"
          :items-per-page="per_page"
          loading-text="로딩중.. 기다려주세요."
          no-data-text="데이터가 없습니다."
          elevation="0"
          hide-default-footer
        >
          <!-- @click:row="detail($event)"
          style="cursor: pointer" -->
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>대행사 리스트</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-dialog
                v-model="dialog"
                hide-overlay
                transition="dialog-bottom-transition"
                max-width="700px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    color="primary white--text"
                    rounded
                    style="font-weight: bold"
                  >
                    대행사 등록
                  </v-btn>
                </template>
                <v-card>
                  <v-toolbar dark color="primary">
                    <v-toolbar-title>대행사 {{ formTitle }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="dialog = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-subheader>대행사</v-subheader>
                  <v-container class="pt-0">
                    <v-row no-gutters>
                      <v-text-field
                        v-model="editedItem.name"
                        label="대행사"
                        hide-details
                      ></v-text-field>
                    </v-row>
                  </v-container>
                  <v-divider></v-divider>
                  <v-subheader>대행사 유형</v-subheader>
                  <v-container class="pt-0">
                    <v-row no-gutters>
                      <v-select
                        v-model="editedItem.type"
                        :items="types"
                        label="대행사 유형"
                        item-text="name"
                        item-value="value"
                        hide-details
                      >
                      </v-select>
                    </v-row>
                  </v-container>
                  <v-divider></v-divider>
                  <v-subheader>계정정보</v-subheader>
                  <v-container class="pt-0">
                    <v-row no-gutters>
                      <v-text-field
                        v-model="editedItem.email"
                        label="아이디"
                        hide-details
                        :readonly="editedIndex > -1"
                      ></v-text-field>
                    </v-row>
                    <v-row no-gutters>
                      <v-text-field
                        v-model="editedItem.password"
                        :label="editedIndex > -1 ? '비밀번호 변경' : '비밀번호'"
                        hide-details
                      ></v-text-field>
                    </v-row>
                    <v-row>
                      <v-card-actions
                        style="width: 100px; margin: 0 auto"
                        class="mb-1"
                      >
                        <v-btn
                          :disabled="!valid"
                          color="blue darken-1 white--text"
                          @click="save()"
                          >{{ formTitle }}
                        </v-btn>
                      </v-card-actions></v-row
                    >
                  </v-container>
                  <v-divider></v-divider>
                  <v-divider></v-divider>
                  <div class="d-flex justify-space-between pr-3">
                    <v-subheader>대행 수수료</v-subheader>
                    <v-btn
                      :disabled="isActive"
                      color="green darken-1 white--text"
                      class="d-block mt-3"
                      style="margin-left: auto"
                      rounded
                      @click="openRateModal('add')"
                      >대행 수수료 추가
                    </v-btn>
                  </div>
                  <v-container>
                    <v-data-table
                      :headers="rateHeaders"
                      :items="rate_items"
                      :loading="loading"
                      loading-text="로딩중.. 기다려주세요."
                      no-data-text="데이터가 없습니다."
                      elevation="0"
                      hide-default-footer
                    >
                      <template v-slot:[`item.edit`]="{ item }">
                        <v-icon
                          class="mr-1"
                          @click="openRateModal('edit', item)"
                          color="green darken-1"
                        >
                          mdi-pencil-box-outline
                        </v-icon>
                      </template>
                      <template v-slot:[`item.delete`]="{ item }">
                        <v-icon
                          class="mr-1"
                          @click="rateDelete(item)"
                          color="red darken-1"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                    </v-data-table>
                  </v-container>
                  <v-card-actions class="justify-end">
                    <v-btn color="primary white--text" @click="close()"
                      >닫기
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialog2" max-width="450px">
                <v-card>
                  <v-toolbar dark color="primary">
                    <v-toolbar-title
                      >대행 수수료 {{ rateTitle }}</v-toolbar-title
                    >
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="rateClose()">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-subheader>대행 수수료</v-subheader>
                  <v-container class="pt-0">
                    <v-row>
                      <v-col>
                        <v-autocomplete
                          v-model="rateEditedItem.customer_user_id"
                          :items="client_items"
                          label="클라이언트"
                          item-text="name"
                          item-value="id"
                          placeholder="클라이언트를 선택하세요."
                          no-data-text="존재하지 않는 클라이언트입니다."
                          hide-details
                          :readonly="rateTitle === '수정'"
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col>
                        <v-text-field
                          v-model="rateEditedItem.fee_rate"
                          @focus="$event.target.select()"
                          prefix="%"
                          reverse
                          type="number"
                          min="0"
                          hide-details
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                  <v-card-actions class="justify-end">
                    <v-btn color="blue darken-1 white--text" @click="rateSave()"
                      >{{ rateTitle }}
                    </v-btn>
                    <v-btn color="primary white--text" @click="rateClose()"
                      >닫기
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <td @click.stop.prevent>
              <v-icon
                class="mr-1"
                @click="editItem(item)"
                color="green darken-1"
              >
                mdi-pencil-box-outline
              </v-icon>
            </td>
          </template>
        </v-data-table>
        <v-pagination
          v-model="page"
          :length="pageCount"
          :total-visible="7"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          color="primary"
          class="float-right mt-2"
        ></v-pagination>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "AgencyManage",
  data() {
    return {
      keyword: "",
      search: "agent_name",
      search_items: [
        {
          value: "agent_name",
          text: "대행사",
        },
        {
          value: "customer_name",
          text: "클라이언트",
        },
      ],

      types: [
        {
          name: "광고주",
          value: "AdvAgent",
        },
        // {
        //   name: "언론사",
        //   value: "MediaAgent",
        // },
      ],

      rateHeaders: [
        {
          value: "customer_name",
          text: "클라이언트",
          filterable: false,
          sortable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          value: "fee_rate",
          text: "수수료율",
          filterable: false,
          sortable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "수정",
          value: "edit",
          sortable: false,
          filterable: false,
          class: "font-weight-black subtitle-2",
          align: "center",
        },
        {
          text: "삭제",
          value: "delete",
          sortable: false,
          filterable: false,
          class: "font-weight-black subtitle-2",
          align: "center",
        },
      ],
      rate_items: [],

      headers: [
        {
          text: "대행사",
          value: "name",
          filterable: false,
          sortable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          value: "email",
          text: "아이디",
          filterable: false,
          sortable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "클라이언트",
          value: "customer",
          sortable: false,
          filterable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "클라이언트 수",
          value: "customer_cnt",
          sortable: false,
          filterable: false,
          class: "font-weight-black subtitle-2",
        },
        {
          text: "수정",
          value: "actions",
          sortable: false,
          filterable: false,
          class: "font-weight-black subtitle-2",
          align: "center",
        },
      ],
      items: [],
      loading: false,
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {
        id: null,
        name: null,
        email: null,
        password: null,
        type: "AdvAgent",
      },
      defaultItem: {
        id: null,
        name: null,
        email: null,
        password: null,
        type: "AdvAgent",
      },
      rateEditedItem: {
        customer_user_id: null,
        fee_rate: 0,
      },
      defaultEditedItem: {
        customer_user_id: null,
        fee_rate: 0,
      },

      dialog2: false,
      isActive: true,
      page: 1,
      per_page: 10,
      pageCount: 1,

      client_items: [],
      rateTitle: "추가",
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    page() {
      this.getData();
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "등록" : "수정";
    },
    valid() {
      if (
        this.editedItem.name &&
        this.editedItem.email &&
        this.editedItem.password
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.rate_items = [];
      });
    },

    rateClose() {
      this.dialog2 = false;
      this.$nextTick(() => {
        this.rateEditedItem = Object.assign({}, this.rateDefaultItem);
      });
    },

    openRateModal(state, item) {
      this.getClient();
      if (state === "add") {
        this.rateTitle = "추가";
        this.client_items = [];
      } else if (state === "edit") {
        this.rateTitle = "수정";
        this.rateEditedItem = Object.assign({}, item);
      }
      this.dialog2 = true;
    },

    rateSave() {
      if (this.rateTitle === "추가") {
        const data = {
          commission_rates: [
            {
              target_user_id: this.rateEditedItem.customer_user_id,
              rate: this.rateEditedItem.fee_rate,
            },
          ],
        };

        this.axios
          .post(`api/v1/manage/agent/${this.editedItem.user_id}`, data)
          .then(() => {
            this.getRate(this.editedItem.user_id);
            this.getData();
            this.$store.commit("alert/showAlert", {
              message: `대행 수수료가 ${this.rateTitle}되었습니다.`,
            });
          });
      } else if (this.rateTitle === "수정") {
        const data = {
          rate: this.rateEditedItem.fee_rate,
        };

        this.axios
          .patch(
            `api/v1/manage/agent/${this.editedItem.user_id}/${this.rateEditedItem.agent_id}`,
            data
          )
          .then(() => {
            this.getRate(this.editedItem.user_id);
            this.getData();
            this.$store.commit("alert/showAlert", {
              message: `대행 수수료가 ${this.rateTitle}되었습니다.`,
            });
          });
      }
    },
    rateDelete(item) {
      if (confirm("대행 수수료를 삭제하시겠습니까?")) {
        let id = this.editedItem.user_id;
        this.axios
          .delete(
            `api/v1/manage/agent/${this.editedItem.user_id}/${item.agent_id}`
          )
          .then(() => {
            this.getRate(id);
            this.$store.commit("alert/showAlert", {
              message: `대행 수수료가 삭제되었습니다.`,
            });
          });
      }
      this.getData();
    },

    refresh() {
      this.search = this.search_items[0].value;
      this.keyword = "";
      this.getData();
    },

    getClient() {
      let clients = [];
      this.axios.get("api/v1/admin/clients").then((res) => {
        if (res.data.data.clients.length > 0) {
          res.data.data.clients.map((item) => {
            clients.push({
              id: item.user.id,
              name: item.name,
            });
          });
        }
        this.client_items = clients;
      });
    },

    getData() {
      this.loading = true;
      let items = [];
      let url = `api/v1/manage/agent?page=${this.page}&per_page=${this.per_page}&${this.search}=${this.keyword}`;
      this.axios
        .get(url, {})
        .then((res) => {
          if (res.data.agents.data.length > 0) {
            res.data.agents.data.map((item) => {
              items.push({
                id: item.id,
                name: item.name,
                email: item.email,
                type: item.type,
                type_value: item.type_eng,
                user_id: item.user_id,
                rate: item.rate,
                customer: item.customer_cnt === 0 ? "" : item.customer,
                customer_cnt: item.customer_cnt,
              });
            });
          }
          this.items = items;
          if (
            Number.isInteger(res.data.agents.total / this.per_page) === false
          ) {
            this.pageCount =
              Math.floor(res.data.agents.total / this.per_page) + 1;
          } else {
            this.pageCount = res.data.agents.total / this.per_page;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    // detail(item) {
    //   this.$router.push(`/agencies/detail/` + item.id);
    // },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.items[this.editedIndex], this.editedItem);
        const data = {
          name: this.editedItem.name,
          email: this.editedItem.email,
          password: this.editedItem.password,
          type: this.editedItem.type,
        };
        this.axios
          .put("api/v1/manage/agent/" + this.editedItem.id, data)
          .then(() => {
            this.getData();
            this.$store.commit("alert/showAlert", {
              message: `대행사가 수정되었습니다.`,
            });
          })
          .catch((err) => {
            this.$store.commit("alert/showAlert", {
              message: `${err.response.data.message}`,
            });
          });
      } else {
        this.items.push(this.editedItem);
        const data = {
          name: this.editedItem.name,
          email: this.editedItem.email,
          password: this.editedItem.password,
          type: this.editedItem.type,
        };
        this.axios
          .post("api/v1/manage/agent", data)
          .then(() => {
            this.getData();
            this.isActive = false;
            this.$store.commit("alert/showAlert", {
              message: `대행사가 등록되었습니다. 이제 대행 수수료 등록이 가능합니다.`,
            });
          })
          .catch((err) => {
            this.$store.commit("alert/showAlert", {
              message: `${err.response.data.message}`,
            });
          });
      }
      this.getData();
    },
    editItem(item) {
      this.getRate(item.id);
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.type = this.editedItem.type_value;
      this.dialog = true;
      this.isActive = false;
    },

    getRate(id) {
      this.axios.get(`api/v1/manage/agent/${id}`).then((res) => {
        this.rate_items = res.data?.contracts;
      });
    },
  },
};
</script>
